"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react")); // eslint-disable-next-line


var context = _react["default"].createContext({});

if (typeof document !== 'undefined') {
  context = _react["default"].createContext(window.__routeInfo);
}

var _default = context;
exports["default"] = _default;