"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _onLocationChange = _interopRequireDefault(require("../utils/onLocationChange"));

var useLocation = function useLocation() {
  var _useState = (0, _react.useState)(),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      location = _useState2[0],
      setLocation = _useState2[1]; // eslint-disable-next-line


  var _useState3 = (0, _react.useState)(0),
      _useState4 = (0, _slicedToArray2["default"])(_useState3, 2),
      _ = _useState4[0],
      setCount = _useState4[1];

  (0, _react.useEffect)(function () {
    return (0, _onLocationChange["default"])(function (location) {
      setLocation(location);
      setCount(function (old) {
        return old + 1;
      });
    });
  }, []);
  return location;
};

var _default = useLocation;
exports["default"] = _default;