"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SiteData = SiteData;
exports.withSiteData = withSiteData;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _react = _interopRequireDefault(require("react"));

var _useSiteData = _interopRequireDefault(require("../hooks/useSiteData"));

function SiteData(_ref) {
  var children = _ref.children;
  return children((0, _useSiteData["default"])());
}

function withSiteData(Comp) {
  return function componentWithSiteData(props) {
    var routeData = (0, _useSiteData["default"])();
    return _react["default"].createElement(Comp, (0, _extends2["default"])({}, props, routeData));
  };
}