"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "routePathContext", {
  enumerable: true,
  get: function get() {
    return _routePathContext["default"];
  }
});
exports.useRoutePath = void 0;

var _react = require("react");

var _routePathContext = _interopRequireDefault(require("../context/routePathContext"));

var _utils = require("../utils");

var useRoutePath = function useRoutePath(routePath) {
  var routePathContextValue = (0, _react.useContext)(_routePathContext["default"]); // If we are in a routePathContext, use it always

  if (routePathContextValue) {
    return routePathContextValue;
  } // Clean the path


  return (0, _utils.getRoutePath)(routePath);
};

exports.useRoutePath = useRoutePath;