"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "staticInfoContext", {
  enumerable: true,
  get: function get() {
    return _staticInfoContext["default"];
  }
});
exports.useStaticInfo = void 0;

var _react = require("react");

var _staticInfoContext = _interopRequireDefault(require("../context/staticInfoContext"));

var useStaticInfo = function useStaticInfo() {
  return (0, _react.useContext)(_staticInfoContext["default"]);
};

exports.useStaticInfo = useStaticInfo;