"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RouteData = RouteData;
exports.withRouteData = withRouteData;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _react = _interopRequireDefault(require("react"));

var _useRouteData = _interopRequireDefault(require("../hooks/useRouteData"));

function RouteData(_ref) {
  var children = _ref.children;
  return children((0, _useRouteData["default"])());
}

function withRouteData(Comp) {
  return function componentWithRouteData(props) {
    var routeData = (0, _useRouteData["default"])();
    return _react["default"].createElement(Comp, (0, _extends2["default"])({}, props, routeData));
  };
}